import { useRef, useCallback, useMemo, useEffect } from 'react'
export function useInView(
  callback: () => any,
  enabled?: boolean,
  { root, rootMargin, threshold }: IntersectionObserverInit = {}
) {
  // Ensure our callback is up to date
  // There is no need to recreate the observer when the callback changes
  const callbackRef = useRef(callback)
  useEffect(() => {
    callbackRef.current = callback
  }, [callback])
  // Recreate the observer any time any of its parameters change
  const observer = useMemo(() => {
    const observer = new IntersectionObserver(
      entries =>
        entries.forEach(entry => {
          if (!enabled) {
            return
          }
          if (entry.isIntersecting) {
            callbackRef.current()
          }
        }),
      { root, rootMargin, threshold }
    )
    return observer
  }, [root, rootMargin, threshold, enabled])
  // Return a ref to be used on the element we are observing
  const ref = useRef<Element>()
  const setRef = useCallback(
    (el: any) => {
      // If this ref has been used before, unobserve the previously observed element...
      if (ref.current) {
        observer.unobserve(ref.current)
      }
      ref.current = el
      // ...and observe the new element
      if (ref.current) {
        observer.observe(ref.current)
      }
    },
    [observer]
  )
  return setRef
}
